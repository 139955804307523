import { PaymentEventTypes } from "./events-types";
import { SOCIAL, VIDEO, WEBPAGE } from "./links";
import { ONBOARDING, TOPUP, ROOT, APP, DASHBOARD } from "./routes";
export * from "./page-names";
export * from "./tracking-events";

export const HYPER_COIN_TO_NAIRA_RATE = 5; // 1 hyper coin = 5 naira
export const HYPER_COIN_TO_DOLLAR_RATE = 1 / 142; // 1 dollar = 142 hypercoin
export const FIREBASE_COLLECTIONS = {
  referral: "referral",
};

export const EventTypes = {
  PAYMENTS: PaymentEventTypes,
};

export const ROUTES = { ONBOARDING, TOPUP, ROOT, APP, DASHBOARD };
export const LINKS = { WEBPAGE, VIDEO, SOCIAL };
export const LEGACY_FLOW_SUFFIX = "2";
export const MVM_EMAIL = "info@mvm.gg";

export const LogLevel: {
  [key: string]: number;
} = {
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
  trace: 5,
} as const;

export enum AuthFlow {
  LOGIN = "login",
  SIGNUP = "signup",
}
