import React, {
  DragEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import {
  ReferrerInfo,
  ChallengeInvitation,
} from "@nestcoinco/hyper-api-gateway-api-client";

import games from "../../contents/games";
import whyPlay from "../../contents/why-play";
import Logo from "../../images/hyper-full-white.svg";
import ChooseGameImg from "../../images/choose-game.png";
import ImageOneVsOneResult from "../../images/one-vs-one-result.png";
import ImageXIcon from "../../images/x.svg";
import ImageInstagram from "../../images/instagram-colored.svg";
import ImageTiktok from "../../images/tiktok.svg";
import HeroImg from "../../images/iphone.png";
import CloseIcon from "../../images/close-icon-purple.svg";
import PlayerOneAvatarImg from "../../images/Avatar-2.svg";
import PlayerTwoAvatarImg from "../../images/Avatar-3.svg";
import HyperCoinImg from "../../images/hyper-coin.svg";
import AndroidImg from "../../images/android-icon.svg";
import PalmPlayImg from "../../images/palmplay-icon.png";
import ImageAppleLogo from "../../images/apple-logo-white.svg";
import PhoneIconImg from "../../images/phone-icon.svg";
import ArrowLeftIcon from "../../images/arrow-left-icon.svg";
import "./LandingPage.scss";
import KongClimbSignup from "../../images/games/kong-climb-signup.png";
import { handleGetReferrerInfo } from "../../api-clients/referralsClient";
import { renderHeaders } from "../../utils/renderHeaders";
import { setStoredReferrerInfo } from "../../utils/storage";
import CircularButton from "../../components/Buttons/CircularButton/CircularButton";
import { ROUTES, LINKS } from "../../utils/constants";
import { handleGetChallengeInvitationDetails } from "../../api-clients/challengeInvitationsClient";
import { useDownloadApp } from "../../hooks/useDownloadApp";
import { Stack } from "react-bootstrap";
import faqs from "../../contents/faqs";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CollapsibleList from "../../components/CollapsibleList/CollapsibleList";
import FloatingQr from "../../components/FloatingQr/FloatingQr";
import QrCode from "../../components/QrCode/QrCode";
import { Options } from "qr-code-styling";
import AppDownloadButton from "../../components/Buttons/AppDownloadButton/AppDownloadButton";
import ImageSamsungStoreIcon from "../../images/samsung-galaxy-store-logo.png";

enum DownloadOptions {
  ANDROID = "Android",
  TRANSSION = "Transsion",
  SAMSUNG = "Samsung",
  APPLE = "Apple",
}

const downloadOptions = [
  {
    name: DownloadOptions.ANDROID,
    image: AndroidImg,
    url: `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.START_DOWNLOAD}`,
  },
  {
    name: DownloadOptions.TRANSSION,
    image: PalmPlayImg,
    url: LINKS.WEBPAGE.PALM_STORE_HYPER_PAGE,
  },
  {
    name: DownloadOptions.SAMSUNG,
    image: ImageSamsungStoreIcon,
    url: LINKS.WEBPAGE.GALAXY_STORE,
  },
  { name: DownloadOptions.APPLE, image: ImageAppleLogo, url: "" },
];

function LandingPage() {
  useDownloadApp(); // prefetch apk for use later on

  return (
    <>
      {renderHeaders({
        title:
          "Battle Friends in Fun Games & Make Money | Hyper by Metaverse Magna",
        description:
          "Battle friends & frenemies in fun games like candy crush, temple run, and many more. Win your friends, to win the prize pool. Download Hyper Android APK to get started",
      })}
      <Container fluid className="position-relative px-2 px-lg-0 LandingPage">
        <HeroSection />
        <Row className="body-content d-flex flex-column">
          <WhyPlayGamesSection />
          <ExcitingGamesSection />
          <HowToPlayAndWinSection />
          <Faqs />
        </Row>
        <JoinWhatsAppCommunitySection />
        <DesktopFooter />
        <Footer />
      </Container>
    </>
  );
}

function HeroSection() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [referrerInfo, setReferrerInfo] = useState<ReferrerInfo | null>(null);
  const [challengeDetails, setChallengeDetails] =
    useState<ChallengeInvitation>();
  const [flow, setFlow] = useState<"default" | "referral" | "challenge">(
    "default"
  );
  const mainCopyRef = useRef("");
  const referrerId = queryParams.get("referrer");
  const challengeId = queryParams.get("challengeId");
  const customQrOptions: Options = {
    width: 95,
    height: 95,
    type: "canvas",
    image: "",
    backgroundOptions: {
      color: "",
    },
  };

  switch (flow) {
    case "challenge":
      mainCopyRef.current = `Beat ${
        challengeDetails?.inviter.username || ""
      } at ${
        challengeDetails?.games ? challengeDetails?.games[0].name : ""
      } & ${
        challengeDetails?.rewardAmount
          ? "Win Their Money."
          : "get bragging rights"
      }`;
      break;
    case "referral":
      mainCopyRef.current = "Play fun games, compete & win cash";
      break;
    default:
      mainCopyRef.current = "Play fun games, compete & win";
      break;
  }

  useEffect(() => {
    if (!referrerId && !challengeId) {
      setFlow("default");
    } else if (referrerId && !challengeId) {
      setFlow("referral");
      return;
    } else if (referrerId && challengeId) {
      setFlow("challenge");
    }
  }, [challengeId, referrerId]);

  useEffect(() => {
    if (!referrerId) return;
    const getReferrerInfo = async () => {
      const info = await handleGetReferrerInfo(referrerId);
      if (!info) return;
      setReferrerInfo(info);
      setStoredReferrerInfo(info);
    };

    getReferrerInfo();
  }, [referrerId]);

  useEffect(() => {
    if (!challengeId) return;
    const getChallengeDetails = async () => {
      const details = await handleGetChallengeInvitationDetails(challengeId);
      if (!details) return;
      setChallengeDetails(details);
    };
    getChallengeDetails();
  }, [challengeId]);

  return (
    <>
      <Col className="App-bar d-flex justify-content-center px-0 py-4 position-fixed">
        <Link to="/">
          <Image className="img" src={Logo}></Image>
        </Link>
      </Col>
      <Row
        as="header"
        className="text-white text-center text-md-start d-flex flex-column position-relative pb-5 Header"
      >
        <Col className="pb-3 isolate pt-4 pt-lg-0 d-flex flex-column flex-lg-row align-items-center">
          <Col
            as="h1"
            className="
              heading text-center text-lg-start flex-grow-0 fs-1 px-0 mb-0 pt-5 pt-lg-1 pb-1 hero-text
            "
          >
            <span className="font-lg mb-0 d-lg-none">
              {mainCopyRef.current}
            </span>
            <Stack className="d-none d-lg-flex desktop-hero-text">
              <Stack className="copy-text">
                <p className="mb-0 main-text">
                  Play fun games, compete &
                  <br className="d-none d-lg-inline" /> win{" "}
                  <span className="money-bag">💰💰💰</span>
                </p>
                <p className="fs-4 fw-normal mb-0 sub-text">
                  One app with 100s of the best games from the App Store
                </p>
              </Stack>
              <Stack
                direction="horizontal"
                className="align-items-end gap-4"
                style={{ width: "max-content" }}
              >
                <Stack className="align-items-center gap-0 p-2 bg-white rounded-4">
                  <Stack
                    direction="horizontal"
                    className="gap-1 p-1 scan-heading"
                  >
                    <Image
                      src={PhoneIconImg}
                      alt="Phone icon"
                      width={16}
                      height={16}
                    />
                    <span className="fw-semibold">Scan QR</span>
                  </Stack>
                  <QrCode
                    value="https://hypergame.onelink.me/4tib/dw8iofia"
                    className="barcode"
                    customOptions={customQrOptions}
                  />
                </Stack>
                <Stack className="justify-content-end download-cta">
                  <p className="fw-semibold mb-0 download-text">
                    Hyper is available on
                  </p>
                  <Stack direction="horizontal" className="download-links">
                    {downloadOptions.map((option) => (
                      <span
                        key={option.name}
                        className={`download-link ${
                          option.name === DownloadOptions.APPLE ? "beta" : ""
                        } `}
                      >
                        <Image
                          src={option.image}
                          alt={option.name}
                          width={30}
                          height={30}
                        />
                      </span>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {flow === "default" && (
              <span className="d-lg-none">
                <br />
                💰💰💰
              </span>
            )}
          </Col>
          {referrerInfo && flow === "referral" && (
            <p className="referrer-text p-0 my-3">
              @{referrerInfo.username} is gifting
              <span className="bonus-fire-text ms-1">100% bonus 🔥</span>on 1st
              deposit
            </p>
          )}
          {flow === "challenge" && !!challengeDetails?.rewardAmount && (
            <p className="referrer-text my-0 d-flex align-items-center">
              <span>Prize:</span>
              <Image
                src={HyperCoinImg}
                height={15}
                width={15}
                className="ms-1"
              />
              <span>{challengeDetails?.rewardAmount} HPR</span>
            </p>
          )}
          {flow !== "challenge" && (
            <>
              <Image className="mt-3 hero-image" src={HeroImg} />
            </>
          )}
          {flow === "challenge" && (
            <div className="challenge-img-container mt-2">
              <div className="challenge-info-container">
                <p className="d-flex justify-content-end m-1">
                  <CircularButton
                    color="transparent"
                    image={CloseIcon}
                    buttonClass="close-btn mb-1 p-0"
                  />
                </p>
                <h4 className="challenge-heading mt-1">
                  Challenge {challengeDetails?.inviter.username || ""}
                </h4>

                <div className="player-imgs-box">
                  <div className="player-one-img-box  rounded-circle d-flex justify-content-center  align-align-items-center ">
                    <Image
                      src={PlayerOneAvatarImg}
                      className="player-avatar d-block "
                      width={24}
                      height={25}
                    />
                  </div>
                  <div className="player-two-img-box rounded-circle ">
                    <Image
                      src={
                        challengeDetails?.inviter.avatarUrl ||
                        PlayerTwoAvatarImg
                      }
                      className="player-avatar d-block "
                      width={24}
                      height={25}
                    />
                  </div>
                </div>

                <div className="text-center">
                  <Image
                    src={
                      challengeDetails?.games &&
                      challengeDetails.games[0].thumbnail
                    }
                    className="game-logo"
                  />
                  <span className="game-text">
                    {challengeDetails?.games
                      ? challengeDetails.games[0].name
                      : ""}
                  </span>
                </div>

                {!!challengeDetails?.rewardAmount && (
                  <>
                    <p className="winner-gets-text text-center ">Winner gets</p>

                    <p className="bounty-text text-center ">
                      <Image src={HyperCoinImg} className="hypercoin-img" />{" "}
                      {challengeDetails.rewardAmount}
                    </p>
                  </>
                )}
                <Button className="challenge-with-btn">
                  {!!challengeDetails?.stakeAmount ? (
                    <>
                      <span className="challenge-with-text">
                        Challenge with
                      </span>
                      <Image src={HyperCoinImg} height={8} width={8} />
                      <span className="challenge-with-text">
                        {challengeDetails?.stakeAmount}
                      </span>
                    </>
                  ) : (
                    <span className="challenge-with-text">Challenge</span>
                  )}
                </Button>
              </div>
            </div>
          )}
        </Col>
        <FloatingQr />
      </Row>
    </>
  );
}

function WhyPlayGamesSection() {
  return (
    <Col className="mt-15 page-section WhyPlaySection">
      <h2 className="gradient-text pb-0 why-play-title">
        Why play games <br className="d-none d-md-inline" /> on Hyper?
      </h2>
      <Col className="d-flex justify-content-between why-play-grid">
        {whyPlay.map((item, i) => (
          <div
            key={item.key}
            className="d-flex flex-column align-items-center why-play"
          >
            <img src={item.image} alt="" />
            <p className="text-center py-1 mt-md-3 mb-0 colored">{item.copy}</p>
            <p className="text-center py-1 text-white mb-0">{item.subCopy}</p>
          </div>
        ))}
      </Col>
    </Col>
  );
}

const handleDragStart: DragEventHandler = (e) => e.preventDefault();

const gameItems = games.map((item) => (
  <div
    key={item.key}
    onDragStart={handleDragStart}
    className="col d-flex flex-column justify-content-between gap-1 gap-lg-3 mx-lg-4 fancy-border game-item"
  >
    <img src={item.image} alt="" style={{ objectFit: "contain" }} />
    <p className="text-center text-white mb-0 px-1">{item.content}</p>
  </div>
));

const resposiveValues = {
  992: {
    items: 2,
  },
  1200: {
    items: 3,
  },
};

enum NavDirection {
  PREVIOUS = "prev",
  NEXT = "next",
}

function ExcitingGamesSection() {
  const carouselSectionRef = useRef<HTMLDivElement>(null);
  const [padding, setPadding] = useState(0);

  const syncState = () => {
    const percentageForPadding = 0.13;
    const { current } = carouselSectionRef;
    if (current) {
      setPadding(current.offsetWidth * percentageForPadding);
    }
  };

  useEffect(syncState, []);

  const renderNavButtons = useCallback((dir: NavDirection) => {
    return (
      <button className="carousel-nav-button">
        <Image
          src={ArrowLeftIcon}
          alt="Next button"
          style={{
            rotate: dir === NavDirection.NEXT ? "180deg" : "0deg",
          }}
        />
      </button>
    );
  }, []);

  return (
    <Col className="px-3 d-flex flex-column ExcitingGames">
      <h2 className="gradient-text">
        Play thrilling Games
        <br className="d-none d-md-inline" /> with friends on Hyper
      </h2>
      <div ref={carouselSectionRef} className="d-none d-lg-block game-carousel">
        <AliceCarousel
          items={gameItems}
          responsive={resposiveValues}
          mouseTracking
          autoPlay
          autoPlayStrategy="none"
          autoPlayInterval={1000}
          animationDuration={1000}
          animationType="fadeout"
          infinite
          paddingRight={padding}
          onResized={syncState}
          disableDotsControls
          disableButtonsControls
          renderPrevButton={() => renderNavButtons(NavDirection.PREVIOUS)}
          renderNextButton={() => renderNavButtons(NavDirection.NEXT)}
        />
      </div>
      <Col className="d-lg-none game-grid">{gameItems}</Col>
    </Col>
  );
}

function HowToPlayAndWinSection() {
  return (
    <Col className="d-flex flex-column page-section HowToPlayAndWin">
      <h2 className="py-0 gradient-text">
        How to Battle
        <br className="d-none d-lg-inline" /> Friends and win
        <span className="dollar-icon"> 💰💰💰</span>
      </h2>
      <Col className="d-flex flex-wrap flex-lg-nowrap justify-content-lg-between InfoItems">
        <Col className="green text-white d-flex flex-column align-items-center justify-content-between pt-4 mb-3 m-lg-0 how-to-container">
          <div className="d-flex flex-column align-items-center how-to-item">
            <h3 className="text-white item-count">1</h3>
            <h6 className="item-title">Download Hyper & Sign up</h6>
            <p className="item-copy">
              Tap download & install the app from your phone's download folder
              to sign up.
            </p>
          </div>
          <Image src={KongClimbSignup} alt="Kong Climb game demo" />
        </Col>
        <Col className="blue text-white d-flex flex-column align-items-center justify-content-between pt-4 my-3 m-lg-0 how-to-container">
          <div className="d-flex flex-column align-items-center how-to-item">
            <h3 className="text-white item-count">2</h3>
            <h6 className="item-title">Choose a Game</h6>
            <p className="item-copy">
              Play for free or play for cash in multi-player tournaments &
              head-to-head contests.
            </p>
          </div>
          <Image src={ChooseGameImg} alt="Hyper dashboard" />
        </Col>
        <Col className="orange text-white d-flex flex-column align-items-center justify-content-between pt-4 my-3 m-lg-0 how-to-container">
          <div className="d-flex flex-column align-items-center how-to-item">
            <h3 className="text-white item-count">3</h3>
            <h6 id="canvas" className="item-title">
              Beat Your Opponent & Win Cash
            </h6>
            <p className="item-copy">
              Beat your opponent, level up & win the 💰 prize.
            </p>
          </div>
          <Image src={ImageOneVsOneResult} alt="1v1 game result" />
        </Col>
      </Col>
    </Col>
  );
}

function Faqs() {
  return (
    <div className="d-none d-lg-flex justify-content-lg-between gap-5 text-start page-section Faqs">
      <Stack gap={2} className="copy-section">
        <p className="fs-1 fw-bold mb-0 copy">
          Some things
          <br className="d-none d-lg-inline" /> you may want
          <br className="d-none d-lg-inline" /> to know
        </p>
        <p className="fs-5 sub-copy">
          We&apos;ve answered some questions asked by the community.
          Couldn&apos;t find an answer to your question? Feel free to{" "}
          <a href="mailto:info@mvm.gg">email us!</a>
        </p>
      </Stack>

      <CollapsibleList items={faqs} className="questions" />
    </div>
  );
}

function JoinWhatsAppCommunitySection() {
  return (
    <Row className="foot d-flex flex-column d-lg-none">
      <Col className="logo-container">
        <Image src={Logo} />
      </Col>
      <Col className="d-flex justify-content-center pb-3">
        <Row className="d-flex justify-content-between w-100">
          <Col className="d-flex justify-content-start align-items-end w-100 p-0 gap-3">
            <a
              href={LINKS.SOCIAL.INSTAGRAM}
              target="_blank"
              rel="noreferrer"
              aria-label="instagram"
            >
              <Image src={ImageInstagram} alt="instagram icon" />
            </a>
            <a
              href={LINKS.SOCIAL.X}
              target="_blank"
              rel="noreferrer"
              aria-label="x"
            >
              <Image src={ImageXIcon} alt="x icon" />
            </a>
            <a
              href={LINKS.SOCIAL.TIKTOK}
              target="_blank"
              rel="noreferrer"
              aria-label="Tik Tok"
            >
              <Image src={ImageTiktok} alt="tiktok icon" />
            </a>
          </Col>
          <Col className="d-flex justify-content-end align-items-end w-100 p-0 gap-2">
            <a
              href={LINKS.WEBPAGE.PRIVACY_POLICY}
              target="_blank"
              rel="noreferrer"
              className="link-text"
            >
              Privacy Policy
            </a>
            <a
              href={LINKS.WEBPAGE.TERMS_AND_CONDITIONS}
              target="_blank"
              rel="noreferrer"
              className="link-text"
            >
              Terms of service
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function DesktopFooter() {
  return (
    <Col className="d-none d-lg-flex flex-row justify-content-between align-items-center DesktopFooter">
      <Stack gap={3} className="contact-details">
        <a href="mailto:info@mvm.gg" className="email">
          info@mvm.gg
        </a>
        <Stack direction="horizontal" gap={3} className="social-links">
          <a
            href="https://www.instagram.com/metaversemagna/"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            <Image
              src={ImageInstagram}
              alt="Instagram Logo"
              width={24}
              height={24}
            />
          </a>
          <a
            href="https://twitter.com/metaversemagna"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            <Image
              src={ImageXIcon}
              alt="X/Twitter Logo"
              width={24}
              height={24}
            />
          </a>
          <a
            href="https://www.tiktok.com/@metaversemagna?_t=8cXDtSY4CKz&_r=1"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            <Image src={ImageTiktok} alt="TikTok Logo" width={24} height={24} />
          </a>
        </Stack>
      </Stack>
      <Col className="logo-container">
        <Image src={Logo} width={178} height={30} />
      </Col>
      <Stack gap={3} className="terms-and-privacy">
        <a
          href="https://mvm.gg/hyper/terms"
          target="_blank"
          rel="noreferrer"
          className="fs-6"
        >
          Terms of service
        </a>
        <a
          href="https://mvm.gg/hyper/privacy"
          target="_blank"
          rel="noreferrer"
          className="fs-6"
        >
          Privacy policy
        </a>
      </Stack>
    </Col>
  );
}

function Footer() {
  return (
    <Col className="App-footer d-flex justify-content-center px-3 py-3 position-fixed d-md-none MobileOnly">
      <AppDownloadButton />
    </Col>
  );
}

export default LandingPage;
